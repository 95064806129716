export const slidedatamain = [
  { name: "Je suis Aymeric.", color: "red" },
  { name: "エメリックです。", color: "#607d8b" },
  { name: "나는에메릭입니다.", color: "red" },
  { name: "Yo soy Aymeric.", color: "#607d8b" },
  { name: "I am Aymeric.", color: "black" },
];
export const slidedatasub = [
  { name: "take photos.", color: "red" },
  { name: "build websites.", color: "#607d8b" },
  { name: "code.", color: "#4096c3" },
  { name: "create designs.", color: "#8059aa" },
  { name: "work for AWS.", color: "#F9A825" },
];
