import React from "react";
export default function PdfPage() {
  return (
    <iframe
      title="resume"
      src="https://aymericfoyer.com/resume.pdf#view=fitV"
      style={{ height: "100vh" }}
      width={"100%"}
    ></iframe>
  );
}
